var globals = require('./globals');
var jwt = require('./helpers/jwt');
var cimpressSession = require('./helpers/cimpressSession')
var cookies = require('./cookies/cookies');
var AuthOptionsBuilder = require('./authOptionsBuilder');
var ReturnUrlBuilder = require('./returnUrlBuilder');
var tracking = require('./tracking');
const shaJs = require('sha.js')
const randomBytes = require('randombytes');
const utils = require('./helpers/utils');

function WebAuth () { 
    // Do not modify the following lines.
    // They will be populated during build time.
    this.version = '6.1.195';
}

WebAuth.prototype.getToken = function () {
    if (!globals.isDepsInitialized()) { return null; }
    var deps = globals.getDeps();

    var token = deps.storage.get(deps.options.storageTokenKey);
    return !token ? null : token.value;
}

WebAuth.prototype.getTokenType = function () {
    if (!globals.isDepsInitialized()) { return null; }
    var deps = globals.getDeps();

    var token = deps.storage.get(deps.options.storageTokenKey);
    return !token ? null : token.type;
}

function redirectAfterSignOutParam () {
    var redirectAfterSignOut = globals.getDeps().options.redirectAfterSignOutCallback();

    return redirectAfterSignOut ? `raso=${encodeURIComponent(redirectAfterSignOut)}` : null;
}

function getLogoutReturnToUrl (url, raso) {
    var signOutUrl = `${url}/sign-out-callback/`;
    if (globals.getDeps().options.developmentMode) {
        signOutUrl = `${url}/`;
    }

    if (raso) {
        return `${signOutUrl}?${raso}`;
    }
    return signOutUrl;
}

WebAuth.prototype.signIn = function (
    additionalReturnUrlQueryParams,
    additionalReturnUrlHashParams,
    options
) { 
    if (!globals.isDepsInitialized()) {
        throw new Error("[WebAuth] Error: Not initialized. Use init().");
    }
    var deps = globals.getDeps();

    if (this.isSignedIn()) {
        return;
    }

    const returnUrl = createReturnUrl(
        additionalReturnUrlQueryParams,
        additionalReturnUrlHashParams
    );
    console.error("[authjs] Debug:redirectURL " + returnUrl);

    var authBuilderOptions = deps.options || {};
    if (options) {
        if (options.allowGuestUser !== undefined) {
            authBuilderOptions.allowGuestUser = options.allowGuestUser;
        }
        if (options.guestReturnUrl !== undefined) {
            authBuilderOptions.guestReturnUrl = options.guestReturnUrl;
        }
        if (options.site !== undefined) {
            authBuilderOptions.site = options.site;
        }
        if (options.skipFasterCheckoutText !== undefined) {
            authBuilderOptions.skipFasterCheckoutText = options.skipFasterCheckoutText;
        }
        if (options.loginContext !== undefined) {
            authBuilderOptions.loginContext = options.loginContext;
        }
        if (options.navHint !== undefined) {
            authBuilderOptions.navHint = options.navHint;
        }
        if (options.requireSession !== undefined) {
            authBuilderOptions.requireSession = options.requireSession;
        }
        if (options.enableGoogleOneTap !== undefined) {
            authBuilderOptions.enableGoogleOneTap = options.enableGoogleOneTap;
        }
        if (options.enforcedEmail !== undefined) {
            authBuilderOptions.enforcedEmail = options.enforcedEmail;
        }
        if (options.customText !== undefined) {
            authBuilderOptions.customText = options.customText
        }
        if (options.isFirstLogin !== undefined) {
            authBuilderOptions.isFirstLogin = options.isFirstLogin;
        }
        if (options.isSteppedUp !== undefined) {
            authBuilderOptions.isSteppedUp = options.isSteppedUp;
        }
        if (options.unificationEntityId !== undefined) {
            authBuilderOptions.unificationEntityId = options.unificationEntityId;
        }
        if (options.vcsStoreUrl !== undefined) {
            authBuilderOptions.vcsStoreUrl = options.vcsStoreUrl;
        }
        if (options.testUserId !== undefined) {
            authBuilderOptions.testUserId = options.testUserId;
        }
        if (options.storeId !== undefined) {
            authBuilderOptions.storeId = options.storeId;
        }
        if (options.vcsStoreLogoUrl !== undefined) {
            authBuilderOptions.vcsStoreLogoUrl = options.vcsStoreLogoUrl;
        }
        if (options.restrictSignup !== undefined) {
            authBuilderOptions.restrictSignup = options.restrictSignup;
        }
        if (options.disableEmailField !== undefined) {
            authBuilderOptions.disableEmailField = options.disableEmailField;
        }
    }
    var authOptionsBuilder = new AuthOptionsBuilder(authBuilderOptions)
        .withReturnUrl(returnUrl);

    if (!utils.isAdfsConnection(deps)) {
        authOptionsBuilder
            .withAnonymousId(cookies.getSessionCookie())
            .withCulture();
        console.error("[authjs] Debug:culture " + authBuilderOptions.culture);
    }

    var self = this;
    tracking.trackSignIn(options && options.trackingCaller, function () {
        self.authorize(authOptionsBuilder.build(), deps);
    });
}

function generateCodeVerifier () {
    return urlEncode(
        randomBytes(32).toString("base64")
    )
}

function createCodeChallenge (codeVerifier) {
    return urlEncode(
        shaJs("sha256")
            .update(codeVerifier)
            .digest("base64")
    )
}

function urlEncode (str) {
    return str
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
}

WebAuth.prototype.authorize = function (options, deps) {
    const verifier = generateCodeVerifier()
    const challenge = createCodeChallenge(verifier)
    
    deps.storage.set(deps.options.storageVerifierKey, 'user', verifier)

    var url = `https://${deps.options.oidc.domain}/authorize`
    url += `?connection=${options.connection}`
    url += `&state=${encodeURIComponent(options.state)}`
    url += `${utils.isAdfsConnection(deps) ? '' : `&wauth=${options.wauth}`}`
    url += `&response_type=${deps.options.oidc.responseType}`
    url += `&client_id=${deps.options.oidc.clientID}`
    url += `&audience=${deps.options.oidc.audience}`
    url += `&code_challenge=${challenge}`
    url += '&code_challenge_method=S256'
    url += `&scope=${encodeURIComponent(deps.options.oidc.scope)}`
    url += `&redirect_uri=${deps.options.oidc.redirectUri}`

    window.location = url;
}

WebAuth.prototype.signOut = function (options) {
    if (!globals.isDepsInitialized()) {
        throw new Error("[WebAuth] Error: Not initialized. Use init().");
    }
    var deps = globals.getDeps();

    if (!this.isSignedIn()) {
        return;
    }

    deps.storage.delete(deps.options.storageTokenKey);
    deps.storage.delete(deps.options.storageRefreshKey);
    cookies.deleteAuthCookie();
    cookies.deletePresumedLoginState();
    
    // We do federated logout only in case of using Cimpress tenant and
    // connection representing Vistaprint Customers.
    var doFederatedLogout = utils.isCustomerConnection(deps);
    var logoutReturnToUrl = getLogoutReturnToUrl(deps.options.signOutUrl, redirectAfterSignOutParam());
    var logoutOptions = { returnTo: logoutReturnToUrl };

    if(options?.forceFederatedLogOut){
        logoutOptions.federated = true
        logoutOptions.forceFederatedLogOut = true;
    }
    if (doFederatedLogout) {
        if (deps.options && deps.options.connection === 'Vistaprint-Staging-Customers') {
            logoutOptions.federated = true;
        } else {
            if (deps.options.connection === 'VP-Customers-Unification') {
                logoutOptions = {
                    clientID: 'KYWF9D4UM3hT8jM1asfQj7X6xSDXZp7R',
                    returnTo: `https://vistaprint-unification.eu.auth0.com/v2/logout?returnTo=${logoutReturnToUrl}&client_id=7KyluKip2WIpBexLw8HuyujKPiSnAuBI`
                }
            } else {
                var vistaprintDomainLogoutUrl = `https://account.vista.com/v2/logout?returnTo=${logoutReturnToUrl}&client_id=YhWiXWN12uDSeYo62BY2uD35vWLeETx1`
                logoutOptions = {
                    clientID: undefined,
                    returnTo: `https://account.vistaprint.com/v2/logout?returnTo=${encodeURIComponent(vistaprintDomainLogoutUrl)}&client_id=YhWiXWN12uDSeYo62BY2uD35vWLeETx1`
                }
            }
        }
    }

    var sessionId = cookies.getSessionCookie();   
    if (sessionId) {
        cimpressSession.closeSession(sessionId);
        cookies.deleteSessionCookie();
    }

    this.logout(logoutOptions);
}

WebAuth.prototype.logout = function (logoutOptions) {
    if (!globals.isDepsInitialized()) {
        throw new Error("[WebAuth] Error: Not initialized. Use init().");
    }
    const deps = globals.getDeps();
    const configCookie = cookies.getConfigCookie();
    const domain = (configCookie && configCookie.domain) || deps.options.oidc.domain

    var url = `https://${domain}/v2/logout`
    url += `?returnTo=${encodeURIComponent(logoutOptions.returnTo)}`
    if (logoutOptions.clientID) url += `&clientID=${logoutOptions.clientID}`
    if (logoutOptions.federated) url += `&federated`
    if(logoutOptions.forceFederatedLogOut){
        url += `&client_id=${clientID}`
    }

    cookies.deleteConfigCookie();
    window.location = url;
}

WebAuth.prototype.isSignedIn = function () {
    if (!globals.isDepsInitialized()) { return false; }

    var deps = globals.getDeps();
    var tokenType = this.getTokenType();
    var enablePresumedLoginState = !deps.options.enableCrossSiteSso;

    if (enablePresumedLoginState) {
        return tokenType === 'user' && cookies.getPresumedLoginState();
    } else {
        return tokenType === 'user';
    }
}

WebAuth.prototype.getProfile = function () {
    var token = this.getToken();

    if (!token) { return null; }

    return jwt.parse(token);
}

WebAuth.prototype.getCanonicalId = function () {
    var profile = this.getProfile();
    if (!profile) { return null }

    return jwt.getCanonicalId(profile);
}

WebAuth.prototype.getRecentAnonymousIds = function () {
    var profile = this.getProfile();
    if (!profile) { return null }

    return jwt.getRecentAnonymousIds(profile);
}

WebAuth.prototype.getIdentity = function () {
    return {
        profile: this.getProfile(),
        accessToken: this.getToken(),
        isSignedIn: this.isSignedIn(),
        canonicalId: this.getCanonicalId(),
        recentAnonymousIds: this.getRecentAnonymousIds(),
        authorizationHeader: this.getAuthorizationHeader(),
        authorization: this.getAuthorization()
    }
}

WebAuth.prototype.getAuthorizationHeader = function () {
    var header = this.getAuthorization();

    return header ? `${header.type} ${header.token}` : null
}

WebAuth.prototype.getAuthorization = function () {
    var token = this.getToken();
    if (!token) { return null; }

    return {
        type: "Bearer",
        token: token
    }
}

WebAuth.prototype.onUserIdentityUpdate = function (callback) {
    if (typeof callback !== 'function') { return; }

    if (globals.isCacheInitialized()) {
        callback(globals.getCache());
    }

    window.addEventListener('userIdentity', function (e) { callback(e.detail); });
}

function createReturnUrl (additionalQueryParams, additionalHashParams) {
    const returnUrlBuilder = new ReturnUrlBuilder(window.location);

    if (Array.isArray(additionalQueryParams)) {
        additionalQueryParams.forEach((param) => {
            if (!param) { return; }
            returnUrlBuilder.withAdditionalQueryParam(param.key, param.value)
        });
    }

    if (Array.isArray(additionalHashParams)) {
        additionalHashParams.forEach((param) => {
            if (!param) { return; }
            returnUrlBuilder.withAdditionalHashParam(param.key, param.value)
        });
    }

    return returnUrlBuilder.build();
}

module.exports = WebAuth;
